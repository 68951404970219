<template>
    <main>
		<div class="services-area pt-100">
			<div class="container">
				<div class="row">
                    <div class="col-lg-8">
                        <iframe
							width="100%"
                            height="500px"
							frameborder="0"
							allowfullscreen
							:src="Vedio"
						>
						</iframe>
                        <h3 class="pt-10">{{ Title }}</h3>
                        <p class="frame-bottom-desc">{{ Desc }}</p>
						<a v-if="Link" :href="Link" class="download-btn">Download PDF</a>
                    </div>

                    <div class="col-lg-4 col-xs-12">
						<div class="servicee-sidebar mb-50">
							<div class="sidebar-link grey-bg">
								<h3>STUCK SOMEWHERE ?</h3>
								<div class="support_sidebar">
									<p>
										We are here to help you out. You can learn from our video
										tutorials, ask a question, submit a ticket or contact us
										directly.
									</p>
									<p>
										You can also opt to get personalized support through remote
										desktop software, however, we recommend to go through the
										video tutorials first, if those are of any benefit to you.
									</p>
								</div>

								<h3>CONTACT SUPPORT</h3>
								<ul>
									<li><b>We work</b></li>
									<li>Mon-Sat, 10:30 AM to 8:00 PM (IST)</li>
									<li>support@xtrm.co.in</li>
									<li>+91 2836 227096</li>
								</ul>

							</div>
						</div>
					</div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import Vedio_Data from '.././assets/data/Tutoriales.json'
const Tutorial_Data = Vedio_Data.Tutoriales;
// var Title, Vedio, Desc, Link;
export default {
  name: 'tutoriales',
  data () {
    return {
      Tutorial_Data_Array: [],
      Title:'', 
	  Vedio:'',
      Desc:'',
	  Link:''
    }
  },
  created() {
	this.Tutorial_Data_Array = Tutorial_Data;
	// console.log("Data Is : ", this.Tutorial_Data_Array);
  },
  mounted(){
      if(this.$route.params.id){
        var myid = this.$route.params.id;
		this.Title = this.Tutorial_Data_Array[myid - 1].title;
        this.Vedio = this.Tutorial_Data_Array[myid - 1].link;
        this.Desc = this.Tutorial_Data_Array[myid - 1].desc;
		this.Link = this.Tutorial_Data_Array[myid - 1].pdf_link;
        // console.log("the link is : ", this.Vedio);
      }
  }   
}
</script>

<style>
.download-btn:hover{
	color: #ffffff;
}
</style>